export const roles = {
    ADMIN: "ROLE_ADMIN",
    USER: "ROLE_USER",
};

export const reactionsDict = {
    WAVE: "0x1F44B",
    LIKE: "0x1F44D",
    HEART: "0x2764 0xFE0F",
    JOY: "0x1F602",
    SMILE: "0x1F60A",
}
