import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./TextMentionInput.scss";
import Editor from "@draft-js-plugins/editor";
import { getDefaultKeyBinding } from "draft-js";
import Image from "../image/Image";
import createMentionPlugin, { defaultSuggestionsFilter } from "@draft-js-plugins/mention";

const TextMentionInput = ({ editorState, setEditorState, chatUsers, userImageUrls, onSubmit, editing = true, editorKey = "editor" }) => {
    const editorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [suggestions, setSuggestions] = useState(
        chatUsers.map((user) => ({
            name: `${user.name} ${user.surname}`,
            id: user.id,
            image: userImageUrls[user.id],
        }))
    );

    const onOpenChange = useCallback((_open) => {
        setOpen(_open);
    }, []);

    const onSearchChange = useCallback(
        ({ value }) => {
            setSuggestions(
                defaultSuggestionsFilter(
                    value,
                    chatUsers.map((user) => ({
                        name: `${user.name} ${user.surname}`,
                        id: user.id,
                        image: userImageUrls[user.id],
                    }))
                )
            );
        },
        [chatUsers, userImageUrls]
    );

    const { MentionSuggestions, plugins } = useMemo(() => {
        const mentionPlugin = createMentionPlugin({
            theme: {
                mention: "mention",
                mentionSuggestions: "suggestion-container",
                mentionSuggestionsEntry: "suggestion-entry",
                mentionSuggestionsEntryFocused: "suggestion-entry-focused",
            },
        });
        const { MentionSuggestions } = mentionPlugin;
        return {
            plugins: [mentionPlugin],
            MentionSuggestions,
        };
    }, []);

    useEffect(() => {
        if (editing && editorRef.current && editorState.getCurrentContent().hasText()) {
            editorRef.current.focus();
        }
    }, [editing, editorState]);

    const handleKeyBinding = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            editorRef.current.focus();
            return "submit-message";
        }
        return getDefaultKeyBinding(event);
    };

    const handleKeyCommand = (command) => {
        if (command === "submit-message") {
            onSubmit();
            return "handled";
        }
        return "not-handled";
    };

    const handleEditorChange = (newState) => {
        setEditorState(newState);
    };

    const handleFocus = () => {
        if (editorRef.current && !editorState.getCurrentContent().hasText()) {
            editorRef.current.focus();
        }
    };

    return (
        <>
            <div className="text-field" onClick={handleFocus}>
                <Editor
                    editorKey={editorKey}
                    editorState={editorState}
                    onChange={handleEditorChange}
                    plugins={plugins}
                    ref={editorRef}
                    readOnly={!editing}
                    keyBindingFn={handleKeyBinding}
                    handleKeyCommand={handleKeyCommand}
                />
            </div>
            <MentionSuggestions
                key={editorKey}
                open={open}
                onOpenChange={onOpenChange}
                suggestions={suggestions}
                onSearchChange={onSearchChange}
                entryComponent={(entryProps) => (
                    <div {...entryProps} className="suggestion-entry">
                        <Image
                            imageSource={entryProps.mention.image}
                            name={entryProps.mention.name}
                            color={chatUsers.find((user) => user.id === entryProps.mention.id).color}
                            radius={30}
                        />
                        <span className="suggestion-name">{entryProps.mention.name}</span>
                    </div>
                )}
            />
        </>
    );
};

export default TextMentionInput;