import React, { useCallback, useEffect, useRef, useState, forwardRef } from "react";
import "./Message.scss";
import { useParams } from "react-router-dom";
import { usePopper } from "react-popper";
import Modal from "react-modal";
import { post, put, remove } from "../../api";
import ReactionSelector from "../reaction/reaction-selector/ReactionSelector";
import ReactionCounter from "../reaction/reaction-counter/ReactionCounter";
import Attachment from "../attachment/Attachment";
import Image from "../image/Image";
import classNames from "classnames";
import { convertFromRaw, EditorState } from "draft-js";
import TextMentionInput from "../text-mention-input/TextMentionInput";
import { convertFromJson } from "../mention/mentionUtil";

const Message = forwardRef(({ message, chatUsers, isSearched, sender, userImageUrls }, ref) => {
    const { userId } = useParams();
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: "auto"
    });

    const stringToDraftJS = (value) => {
        return EditorState.createWithContent(convertFromRaw(JSON.parse(value)));
    };

    const popupRef = useRef();
    const [isEditing, setIsEditing] = useState(false);
    const editRef = useRef(null);
    const [isHovered, setIsHovered] = useState(false);
    const [editorState, setEditorState] = useState(null);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    useEffect(() => {
        setEditorState(stringToDraftJS(message.content));
    }, [message.content]);

    useEffect(() => {
        if (isEditing && editRef.current) {
            editRef.current.focus();
        }
    }, [isEditing]);

    const togglePopup = useCallback((event) => {
        event.stopPropagation();
        setPopupVisible(prev => !prev);
    }, []);

    const handleClickOutside = useCallback((event) => {
        if (popupRef.current && !popupRef.current.contains(event.target) && !referenceElement.contains(event.target)) {
            setPopupVisible(false);
        }
    }, [referenceElement]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);

    const onSubmit = () => {
        const { newMessage, mentions, json } = convertFromJson(editorState);

        if (newMessage === "" && message.attachments.length === 0) {
            setDeleteModalOpen(true);
        } else if (newMessage !== "") {
            message.content = JSON.stringify(json);
            put(`chats/${message.chat_id}/messages/${message.id}`, message);
            post(`chats/${message.chat_id}/mentions/${message.id}`, {
                mentions: mentions,
            });
        }

        setIsEditing(false);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        setEditorState(stringToDraftJS(message.content));
    };

    const handleEditMessage = () => {
        setIsEditing(true);
        setPopupVisible(false);
    };

    const deleteMessageAndCloseModal = () => {
        remove(`chats/${message.chat_id}/messages/${message.id}`);
        setDeleteModalOpen(false);
    };

    return (
        <>
            <div
                className="message"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                ref={ref}
            >
                <ReactionSelector
                    isVisible={isHovered}
                    messageId={message.id}
                    position={userId === message.user_id ? "right" : "left"}
                    reactions={message.reactions}
                />
                <div className={`main ${userId === message.user_id ? "my-message" : "other-message"}`} key={message.id}>
                    <Image
                        imageId={sender.document_id}
                        name={`${sender.name} ${sender.surname}`}
                        color={sender.color}
                        radius={35}
                        imageSource={userImageUrls[sender.id]}
                    />
                    <div>
                        <div
                            className={classNames("message-content", {
                                "my-message": userId === message.user_id,
                                "other-message": userId !== message.user_id,
                                "searched-message": isSearched,
                            })}
                            onBlur={handleCancelEdit}
                        >
                            {editorState && (
                                <TextMentionInput
                                    key={message.id}
                                    editorState={editorState}
                                    setEditorState={setEditorState}
                                    chatUsers={chatUsers}
                                    userImageUrls={userImageUrls}
                                    onSubmit={onSubmit}
                                    editing={isEditing}
                                    editorKey={message.id}
                                />
                            )}
                            {message.attachments &&
                                message.attachments.map((attachment, index) => (
                                    <Attachment key={index} attachment={attachment} isEditing={isEditing} />
                                ))}
                        </div>
                    </div>
                    {userId === message.user_id && (
                        <img
                            src="/icons/dots_icon.svg"
                            alt="Dots Icon"
                            className="message-options-button"
                            ref={setReferenceElement}
                            onClick={togglePopup}
                        />
                    )}
                    {isPopupVisible && (
                        <div
                            ref={(element) => {
                                setPopperElement(element);
                                popupRef.current = element;
                            }}
                            style={styles.popper}
                            {...attributes.popper}
                            className="popup-menu"
                        >
                            <div onClick={handleEditMessage} className="item">
                                Edit message
                            </div>
                            <div
                                onClick={() => {
                                    setDeleteModalOpen(true);
                                    setPopupVisible(false);
                                }}
                                className="item"
                            >
                                Delete message
                            </div>
                        </div>
                    )}
                </div>
                <ReactionCounter
                    position={userId === message.user_id ? "right" : "left"}
                    messageId={message.id}
                    chatUsers={chatUsers}
                    reactions={message.reactions}
                />
            </div>
            <Modal
                isOpen={isDeleteModalOpen}
                onRequestClose={() => setDeleteModalOpen(false)}
                className="modal"
                overlayClassName="overlay"
                contentLabel="Delete Message Confirmation"
            >
                <div className="modal-content">
                    <p>Are you sure you want to delete this message?</p>
                    <div>
                        <button onClick={deleteMessageAndCloseModal} className="button primary">
                            Yes
                        </button>
                        <button onClick={() => setDeleteModalOpen(false)} className="button secondary">
                            No
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
});

export default Message;
