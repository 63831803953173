import React from "react";
import "./CheckboxInput.scss"
import "../../Form.scss";

const CheckboxInput = ({label, name, formik}) => {
    return (
        <div className="checkbox-input">
            <div className="row">
                <label className="label">
                    {label}
                </label>
                <span>
                    <input
                        type="checkbox"
                        name={name}
                        checked={formik.values[name]}
                        onChange={formik.handleChange}
                    />
                </span>
            </div>
        </div>
    );
}

export default CheckboxInput;