import React, {useState, useEffect} from "react";
import {get} from "../../../../api";
import {useParams} from "react-router-dom";
import ProjectChats from "./ProjectChats";
import "./ChatsSidebar.scss";

const ChatsSidebar = () => {
    const {userId} = useParams();
    const [chats, setChats] = useState([]);
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        if (userId) {
            get(`chats/users/${userId}`)
                .then(chats => {
                    setChats(chats);
                });
        }
    }, [userId]);

    useEffect(() => {
        get(`projects/users/${userId}`)
            .then(projects => {
                setProjects(projects);
            });
    }, []);


    const mapChatsToProjects = (project) => {
        return chats.filter(chat => chat.project_id === project.id);
    }


    return (
        <div className="chats-sidebar">
            {projects.map(project => {
                let projectChats = mapChatsToProjects(project);
                return <ProjectChats key={project.id} project={project} chats={projectChats} setChats={setChats}/>
            })}
        </div>
    )
}


export default ChatsSidebar;