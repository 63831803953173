import React, {useEffect} from "react";
import {useFormik} from "formik";
import TextInput from "../../components/forms/input-primitives/text-input/TextInput";
import "./Login.scss";
import {useDispatch, useSelector} from "react-redux";
import {loginUser} from "../../store/userSlice";
import {useNavigate} from "react-router-dom";
import {roles} from "../../constants";


const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user.user)

    useEffect(() => {
        if (user) {
            if (user.role === roles.ADMIN) {
                navigate("/users");
            }
            else {
                navigate(`/chats/${user.id}`);
            }
        }
    }, [user]);

    const formik = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        onSubmit: values => {
            const loginData = {
                email: values.email,
                password: values.password
            }
            dispatch(loginUser(loginData))
        }
    });


    return (
        <div className="login">
            <form className="form" onSubmit={formik.handleSubmit}>
                <TextInput formik={formik} name="email" label="Email" type="email"/>
                <TextInput formik={formik} name="password" label="Password" type="password"/>
                <button type="submit">Login</button>
            </form>
        </div>
    );
}

export default Login;