import React, {useEffect, useState} from "react";
import UserActionWrapper from "./UserActionWrapper";
import {get} from "../../../api";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";
import {
    saveOrUpdateImage,
    saveUser,
    updateUser
} from "../../../components/forms/CommonFormRequests";
import {useFormik} from "formik";
import "../../../components/forms/Form.scss";
import TextInput from "../../../components/forms/input-primitives/text-input/TextInput";
import ColorInput from "../../../components/forms/input-primitives/color-input/ColorInput";
import RadioInputWrapper from "../../../components/forms/input-primitives/radio-input/RadioInputWrapper";
import RadioInputOption from "../../../components/forms/input-primitives/radio-input/RadioInputOption";
import ImageDropzone from "../../../components/forms/input-primitives/image-dropdown/ImageDropzone";
import CheckboxInput from "../../../components/forms/input-primitives/checkbox-input/CheckboxInput";
import _ from "lodash";
import {roles} from "../../../constants";

const User = () => {
    const {userId} = useParams();
    const isEditing = !!userId;
    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (userId) {
            get(`users/${userId}`)
                .then(user => {
                    setUser(user)
                    formik.setValues(
                        {
                            changePassword: false,
                            name: user.name,
                            surname: user.surname,
                            email: user.email,
                            color: user.color,
                            role: user.role,
                            profilePicture: null,
                        }
                    )
                })
                .catch(error => {
                    console.error("There was an error fetching the user!", error);
                });
        }
    }, [userId]);

    const validationSchema = Yup.object().shape({
        changePassword: Yup.boolean(),
        name: Yup.string()
            .matches(/^[A-Za-z]+$/, "Name must contain only letters and no spaces.")
            .required("Required"),
        surname: Yup.string()
            .matches(/^[A-Za-z]+$/, "Surname must contain only letters and no spaces.")
            .required("Required"),
        email: Yup.string()
            .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format")
            .required("Required"),
        password: Yup.string()
            .when("changePassword", {
                is: true,
                then: schema => schema
                    .required("Required"),
                otherwise: schema => schema.notRequired(),
            }),
        confirmPassword: Yup.string()
            .when("changePassword", {
                is: true,
                then: schema => {
                    return schema
                        .oneOf([Yup.ref("password"), null], "Passwords do not match")
                        .required("Required");
                },
                otherwise: schema => schema.notRequired(),
            }),
        color: Yup.string().required("Required"),
        role: Yup.string().required("Required"),
    });

    const handleSubmit = (values, { setSubmitting, setFieldError }) => {
        const userBody = _.pick(values, "name", "surname", "email", "color", "role");

        if (!isEditing || values.changePassword) {
            userBody.password = values.password;
        }

        let promises = [];

        const saveUserPromise = () => {
            return isEditing ? updateUser(userBody, user.id) : saveUser(userBody);
        };

        if (values.profilePicture) {
            promises.push(
                saveOrUpdateImage(values.profilePicture, isEditing ? user.document_id : null)
                    .then((imageData) => {
                        userBody.document_id = isEditing && user.document_id ? user.document_id : imageData.id;
                        return saveUserPromise();
                    })
            );
        } else {
            promises.push(saveUserPromise());
        }

        Promise.all(promises)
            .then(() => {
                navigate("/users");
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    setFieldError("email", error.response.data);
                } else {
                    console.error("There was an error saving the user!", error);
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    };


    const formik = useFormik({
        initialValues: {
            changePassword: !isEditing,
            name: "",
            surname: "",
            email: "",
            password: "",
            confirmPassword: "",
            color: "",
            role: roles.USER,
            profilePicture: null,
        },
        validationSchema,
        onSubmit: handleSubmit,
    });


    return (
        <UserActionWrapper onSubmit={formik.handleSubmit} path={`/user/${userId}`}
                           user={user ? user : {name: "New", surname: "", color: ""}}>
            <form className="form" onSubmit={formik.handleSubmit}>
                <TextInput label="Name" name="name" formik={formik}/>
                <TextInput label="Surname" name="surname" formik={formik}/>
                <TextInput label="Email" name="email" type="email" formik={formik}/>
                {!isEditing && <TextInput label="Password" name="password" type="password" formik={formik}/>}
                {!isEditing &&
                    <TextInput label="Confirm Password" name="confirmPassword" type="password"
                               formik={formik}/>}
                <ColorInput label="Color" name="color" formik={formik}/>
                <RadioInputWrapper label="Role">
                    <RadioInputOption label="User" name="role" value={roles.USER} formik={formik}/>
                    <RadioInputOption label="Admin" name="role" value={roles.ADMIN} formik={formik}/>
                </RadioInputWrapper>
                <ImageDropzone label="Profile Picture" name="profilePicture" formik={formik}/>
                {isEditing && <CheckboxInput label="Change Password" name="changePassword" formik={formik}/>}
                {formik.values.changePassword && isEditing &&
                    <>
                        <TextInput label="New Password" name="password" type="password" formik={formik}/>
                        <TextInput label="Confirm New Password" name="confirmPassword" type="password" formik={formik}/>
                    </>
                }
            </form>
        </UserActionWrapper>
    );
};

export default User;