import "./Layout.scss";
import {useNavigate} from "react-router-dom";
import Navbar from "../navbar/Navbar";
import {useSelector} from "react-redux";

const Layout = ({children}) => {
    const user = useSelector(state => state.user.user)
    const navigate = useNavigate();

    if (!user) {
        navigate("/login");
        return null;
    }

    return (
        <div className="layout">
            <Navbar/>
            <div className="container">
                {children}
            </div>
        </div>
    );
}

export default Layout;