import React from "react";
import {Link} from "react-router-dom";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";


const ProjectActionWrapper = ({children, onSubmit, path, project}) => {
    return (
        <>
            <header>
                <Breadcrumbs icon="/icons/projects_icon.svg" links={[{to: "/projects", label: "Projects"}, {to: path, label: project.name}]}/>
                <div className="header-button">
                    <button type="button" className="button primary" onClick={onSubmit}>Save</button>
                    <Link to="/projects" className="button secondary">Cancel</Link>
                </div>
            </header>
            <main>
                {children}
            </main>
        </>
    );
}

export default ProjectActionWrapper;