import React, {useEffect, useState} from "react";
import "../../../components/layout/Layout.scss";
import ChatModalWrapper from "./ChatModalWrapper";
import {useParams} from "react-router-dom";
import {get} from "../../../api";
import * as Yup from "yup";
import _ from "lodash";
import {saveChat} from "../../../components/forms/CommonFormRequests";
import {useFormik} from "formik";
import TextInput from "../../../components/forms/input-primitives/text-input/TextInput";
import ColorSelect from "../../../components/forms/input-primitives/select/color-select/ColorSelect";
import RadioInputWrapper from "../../../components/forms/input-primitives/radio-input/RadioInputWrapper";
import RadioInputOption from "../../../components/forms/input-primitives/radio-input/RadioInputOption";
import UserSelect from "../../../components/forms/input-primitives/select/user-select/UserSelect";

const ChatCreationModal = ({projectId, isModalVisible, setModalVisible, setChats}) => {

    const {userId} = useParams();
    const [users, setUsers] = useState([]);


    useEffect(() => {
        get(`project/${projectId}/users`).then(users => {
            setUsers(users);
            const defaultUser = users.find(user => user.id === userId);
            if (defaultUser) {
                formik.setValues(
                    {
                        name: "",
                        type: "task",
                        color: "",
                        userSelectOption: "selectedUsers",
                        project_id: projectId,
                        users: [defaultUser]
                    }
                )
            }
        });
    }, []);

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required("Required"),
        color: Yup.string()
            .required("Required"),
        users: Yup.array()
            .when("type", {
                is: "task",
                then: schema => schema
                    .min(1, "At least one user is required")
                    .required("Required"),
                otherwise: schema => schema.notRequired(),
            }),
        userSelectOption: Yup.string()
            .required("Required"),
        type: Yup.string()
            .required("Required"),
    });

    const handleSubmit = (values, {setSubmitting}) => {
        const chatBody = {
            ..._.pick(values, "name", "color", "type"),
            "project_id": projectId,
        };

        saveChat(chatBody, values.type === "general" ? users : values.users)
            .then(message => {
                setChats(chats => {
                    const index = chats.findIndex(chat => chat.project_id === projectId);

                    let before = chats.slice(0, index);
                    let after = chats.slice(index);

                    return [...before, message, ...after];
                });
                setModalVisible(false);
            })
            .catch(error => {
                console.error("There was an error adding the chat!", error);
            })
            .finally(() => {
                formik.resetForm();
                setSubmitting(false);
            });

    };

    const formik = useFormik({
            initialValues: {
                name: "",
                type: "task",
                color: "",
                project_id: projectId,
                userSelectOption: "",
                users: []
            },
            validationSchema: validationSchema,
            onSubmit: handleSubmit
        }
    );

    useEffect(() => {
        if (formik.values.userSelectOption === "allUsers") {
            formik.setFieldValue("users", users);
        } else {
            const defaultUser = users.find(user => user.id === userId);
            formik.setFieldValue("users", [defaultUser]);
        }
    }, [formik.values.userSelectOption]);

    const colorOptions = [
        {value: "#F23E3E"},
        {value: "#60E691"},
        {value: "transparent"},
    ];

    return (
        <ChatModalWrapper onSubmit={formik.handleSubmit} isModalVisible={isModalVisible}
                          setModalVisible={setModalVisible}>
            <form className="form" onSubmit={formik.handleSubmit}>
                <TextInput label="Name" name="name" formik={formik}/>
                <ColorSelect label="Color" name="color" formik={formik} colors={colorOptions}/>
                <RadioInputWrapper label="Type">
                    <RadioInputOption label="Task" name="type" value="task" formik={formik}/>
                    <RadioInputOption label="General" name="type" value="general" formik={formik}/>
                </RadioInputWrapper>
                <UserSelect label="Users" users={users} name="users" formik={formik}/>
                {formik.values.type === "general" &&
                    <RadioInputWrapper label="">
                        <RadioInputOption label="Add all users" name="userSelectOption" value="allUsers" formik={formik}/>
                        <RadioInputOption label="Add selected users" name="userSelectOption" value="selectedUsers" formik={formik}/>
                    </RadioInputWrapper>
                }
            </form>
        </ChatModalWrapper>
    )

}

export default ChatCreationModal;
