import React from "react";
import { Link } from "react-router-dom";
import "./Breadcrumbs.scss";

const Breadcrumbs = ({ icon, links }) => {
    return (
        <div className="breadcrumbs">
            <Link to={links[0].to} className={`link ${links.length === 1 ? "active" : ""}`}>
                <img src={icon} alt={links[0].label} />
                {links[0].label}
            </Link>
            {links.slice(1).map((link, index) => (
                <React.Fragment key={index}>
                    {index < links.length - 1 && <img src="/icons/path_arrow_icon.svg" alt="Path Arrow Icon" />}
                    <Link to={link.to} className={`link ${index === links.length - 2 ? "active" : ""}`}>{link.label}</Link>
                </React.Fragment>
            ))}
        </div>
    );
};

export default Breadcrumbs;