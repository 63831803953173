import React, {useState, useEffect} from "react";
import "./Chat.scss";
import {dataHeader, get, post} from "../../../../api";
import Image from "../../../../components/image/Image";
import ChatUsers from "./ChatUsers/ChatUsers";
import ChatInput from "../../../../components/chat-input/ChatInput";
import ChatMessages from "./ChatMessages/ChatMessages";
import {useParams} from "react-router-dom";
import ChatCall from "../../../../components/chat-call/ChatCall";

const Chat = () => {
    const {chatId} = useParams();
    const [chat, setChat] = useState();
    const [project, setProject] = useState();
    const [chatUsers, setChatUsers] = useState([]);
    const [userImageUrls, setUserImageUrls] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (chatId) {
            get(`chats/${chatId}`)
                .then(chat => {
                    setChat(chat);

                    if (chat && chat.project_id) {
                        return get(`projects/${chat.project_id}`).then(project => {
                            setProject(project);
                            return chat;
                        });
                    } else {
                        return chat;
                    }
                })
                .then(chat => {
                    if (chat) {
                        return get(`chat/${chatId}/users`)
                    }
                })
                .then((chatUsers) => {
                    if (chatUsers) {
                        setChatUsers(chatUsers);
                    }
                })
                .then(() => {
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });
        }

        return () => {
            setChat(null);
            setProject(null);
            setChatUsers([]);
            setIsLoading(true);
        };
    }, [chatId]);

    useEffect(() => {
        if (chatUsers.length > 0) {
            const userImageUrls = {};
            chatUsers.forEach(chatUser => {
                userImageUrls[chatUser.id] = null;
                chatUser.document_id && get(`documents/${chatUser.document_id}`, dataHeader)
                    .then(image => {
                        const urlCreator = window.URL || window.webkitURL;
                        userImageUrls[chatUser.id] = urlCreator.createObjectURL(image);
                    })
                    .catch(error => {
                        console.error("There was an error fetching the image!", error);
                    });
            });
            setUserImageUrls(userImageUrls);
        }
    }, [chatUsers]);
    return (

        <div className="chat">
            <header className="header">
                {project && (
                    <div className="header">
                        <div className="info">
                            <Image imageId={project.document_id} name={project.name} color={project.color} radius={30}/>
                            <div className="name">
                                {chat.name}
                            </div>
                        </div>
                        <ChatCall chatUsers={chatUsers} userImageUrls={userImageUrls}/>
                        <ChatUsers userImageUrls={userImageUrls}/>
                    </div>
                )}
            </header>
            <main>
                {!isLoading && chatUsers && <ChatMessages chatUsers={chatUsers} userImageUrls={userImageUrls}/>}
            </main>
            <footer className="footer">
                {chat && chatUsers.length > 0 && Object.keys(userImageUrls).length > 0 &&
                    <ChatInput
                        chatName={chat.name}
                        projectName={project ? project.name : null}
                        chatUsers={chatUsers}
                        userImageUrls={userImageUrls}
                    />
                }
            </footer>
        </div>

    );
};

export default Chat;