import React from "react";
import Select, {components} from "react-select";
import customSelectStyles from "../CustomSelectStyles";
import "./ColorSelect.scss"
import "../../../Form.scss"


const ColorSelect = ({label, colors, name, formik, required = true}) => {

    const mapToColorCard = (props) => {
        const { value } = props.data;

        return (
            <components.Option {...props}>
                <div className="dropdown-element" style={{
                    backgroundColor: value,
                }}></div>
            </components.Option>
        );
    };

    const singleValue = ({data}) => (
        <div className="dropdown-element" style={{
            backgroundColor: data.value,
            width: "100%",
        }}></div>
    )

    const handleChange = (selectedOption) => {
        formik.setFieldValue(name, selectedOption.value);
    }

    const isError = formik.errors[name] && formik.touched[name];

    return (
        <div className="color-select">
            <div className="row">
                <label className="label">
                    {required && <span className="required-asterisk">*</span>}
                    {label}
                </label>
                <Select
                    options={colors}
                    name={name}
                    className="select"
                    styles={customSelectStyles(isError)}
                    onBlur={formik.handleBlur}
                    value={colors.find(option => option.value === formik.values[name])}
                    onChange={handleChange}
                    placeholder={isError ? formik.errors[name] : "Select color..."}
                    isSearchable={false}
                    components={{Option: mapToColorCard, SingleValue: singleValue}}
                />
            </div>
        </div>
    );
}

export default ColorSelect;