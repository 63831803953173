import React, {useState, useEffect, useRef, useCallback} from "react";
import {usePopper} from "react-popper";
import Image from "../../../../components/image/Image";
import "./ProjectChats.scss";
import ChatCreationModal from "../ChatCreationModal";
import {Link, useParams} from "react-router-dom";
import useWebSocket from "../../../../websocket/useWebSocket";
import {get, remove} from "../../../../api";
import classNames from "classnames";

const ProjectChats = ({project, chats, setChats}) => {
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [isModalVisible, setModalVisible] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const {lastMention} = useWebSocket();
    const [mentionedChats, setMentionedChats] = useState({});
    const {styles, attributes} = usePopper(referenceElement, popperElement, {
        placement: "bottom-start",
    });

    const {userId, chatId} = useParams();

    const popupRef = useRef();

    const togglePopup = useCallback((event) => {
        event.stopPropagation();
        setPopupVisible(prev => !prev);
    }, []);

    const handleClickOutside = useCallback((event) => {
        if (popupRef.current && !popupRef.current.contains(event.target) && !referenceElement.contains(event.target)) {
            setPopupVisible(false);
        }
    }, [referenceElement]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);

    const handlePopupItemClick = useCallback((event) => {
        event.stopPropagation();
        setModalVisible(true);
        setPopupVisible(false);
    }, []);

    useEffect(() => {
        get(`chats/${chatId}/mentions/${userId}`)
            .then(mentions => {
                mentions.forEach(mention => {
                    setMentionedChats(prev => {
                        return {
                            ...prev,
                            [mention.chat_id]: prev[mention.chat_id] ? prev[mention.chat_id] + 1 : 1,
                        };
                    });
                });
            });
    }, [userId]);

    useEffect(() => {
        if (lastMention) {
            setMentionedChats(prev => {
                return {
                    ...prev,
                    [lastMention.chat_id]: prev[lastMention.chat_id] ? prev[lastMention.chat_id] + 1 : 1,
                };
            });
        }
    }, [lastMention]);

    const deleteMentionsFromChat = (chatId) => {
        remove(`chats/${chatId}/mentions/${userId}`)
            .then(() => {
                if (mentionedChats[chatId]) {
                    setMentionedChats(prev => {
                        const {[chatId]: _, ...newState} = prev;
                        return newState;
                    });
                }
            });
    }

    return (
        <div className="project-chats">
            <ChatCreationModal projectId={project.id} isModalVisible={isModalVisible} setModalVisible={setModalVisible}
                               chats={chats} setChats={setChats}/>
            <div className="info">
                <Image imageId={project.document_id} name={project.name} color={project.color} radius={30}/>
                <div className="name">
                    {project.name}
                </div>
                <img
                    src="/icons/dots_icon.svg"
                    alt="Dots Icon"
                    className="add-chat-button"
                    ref={setReferenceElement}
                    onClick={togglePopup}
                />
            </div>
            <div className="chats">
                {chats.map(chat => (
                    <Link to={`/chats/${userId}/${chat.id}`}
                          className={`chat-info ${chat.type === "general" ? "general" : "task"} ${chatId === chat.id ? "selected" : ""}`}
                          key={chat.id}
                          onClick={() => deleteMentionsFromChat(chat.id)}>
                        <div className="content">
                            <div className={classNames("mention-count", {
                                "hidden": !mentionedChats[chat.id]
                            })}>
                                <div className="dot"/>
                                <div className="count">
                                    {mentionedChats[chat.id]}
                                </div>
                            </div>
                            {chat.color && <div
                                className="color"
                                style={{backgroundColor: chat.color}}
                            />}
                            <div
                                className={classNames("name", {
                                    "unread": mentionedChats[chat.id]
                                })}
                            >
                                {chat.name}
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
            {isPopupVisible && (
                <div
                    ref={(element) => {
                        setPopperElement(element);
                        popupRef.current = element;
                    }}
                    style={styles.popper}
                    {...attributes.popper}
                    className="popup-menu"
                >
                    <div onClick={handlePopupItemClick}>+ Add new group</div>
                </div>
            )}
        </div>
    );
}

export default ProjectChats;