import React from "react";
import Modal from "react-modal";
import "./ChatModalWrapper.scss";

const ChatModalWrapper = ({children, onSubmit, isModalVisible, setModalVisible}) => {
    return (
        <Modal
            isOpen={isModalVisible}
            onRequestClose={() => setModalVisible(false)}
            className="chat-modal-wrapper modal"
            overlayClassName="overlay"
            contentLabel="Add Chat Modal"
        >
            <header className="header">
                <div className="text">
                    <img src="/icons/plus_icon_dark.svg" className="icon" alt="Add"/>
                    Add new group
                </div>
            </header>
            <main className="main">
                {children}
            </main>
            <footer className="footer">
                <div className="button-container">
                    <button type="button" className="button primary" onClick={onSubmit}>Save</button>
                    <button type="button" className="button secondary" onClick={() => setModalVisible(false)}>Cancel
                    </button>
                </div>
            </footer>
        </Modal>
    );
}

export default ChatModalWrapper;