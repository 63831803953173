import {createSlice} from "@reduxjs/toolkit";

const callSlice = createSlice({
    name: "call",
    initialState: {
        callName: null,
        callChannelId: null,
        isConnected: false,
        isCallModalOpen: false,
        callToken: null
    },
    reducers: {
        setCall: (state, action) => {
            state.callName = action.payload.callName;
            state.callChannelId = action.payload.callChannelId;
            state.isCallModalOpen = true;
        },
        connectCall: (state) => {
            state.isConnected = true;
        },
        closeCall: (state) => {
            state.callName = null;
            state.callChannelId = null;
            state.isConnected = false;
            state.isCallModalOpen = false;
            state.callToken = null;
        },
        setCallModalOpen: (state, action) => {
            state.isCallModalOpen = action.payload;
        }
    }
});

export const { setCall, connectCall, closeCall, setCallModalOpen } = callSlice.actions;

export default callSlice.reducer;
