import React from "react";
import "../../Form.scss";
import "./TextInput.scss";

const TextInput = ({ name, label, formik, type = "text", required = true }) => {
    const handleFocus = () => {
        formik.setFieldTouched(name, false);
    }

    return (
        <div className="text-input">
            <div className="row">
                <label className="label">
                    {required && <span className="required-asterisk">*</span>}
                    {label}
                </label>
                <input
                    type={type}
                    className={`${formik.touched[name] && formik.errors[name] ? "error-input" : ""}`}
                    value={formik.errors[name] && formik.touched[name] ? "" : formik.values[name]}
                    onChange={formik.handleChange}
                    name={name}
                    onFocus={handleFocus}
                    placeholder={formik.errors[name] && formik.touched[name] ? formik.errors[name] : ""}
                    onBlur={formik.handleBlur}
                />
            </div>
        </div>
    )
}

export default TextInput;