import React, {useCallback} from "react";
import {useDropzone} from "react-dropzone";
import "../../Form.scss";
import "./ImageDropzone.scss";


const ImageDropzone = ({label, name, formik}) => {
    const onDrop = useCallback((acceptedFiles) => {
        formik.setFieldValue(name, acceptedFiles[0]);
    }, [formik, name]);

    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            "image/jpeg": [".jpg", ".jpeg"], "image/png": [".png"]
        }, onDrop,
    });

    const handleDeleteImage = useCallback(() => {
        formik.setFieldValue(name, null);
    }, [formik, name]);

    const handleChange = (event) => {
        const file = event.currentTarget.files[0];
        formik.setFieldValue(name, file);
    };

    return (
        <div className="image-dropzone">
            <div className="row">
                <label className="label">{label}</label>
                <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} onChange={handleChange} name={name} onBlur={formik.handleBlur}/>
                    {formik.values[name] ? (
                        <div>
                            <p>{formik.values[name].name}</p>
                            <button
                                type="button"
                                onClick={handleDeleteImage}
                                className="dropzone-button"
                            >
                                Delete Image
                            </button>
                        </div>
                    ) : (
                        <div className="upload-image">
                            <div className="upload-image-div">
                                <img src="/icons/upload_image_icon.svg" alt="Upload Icon"/>
                            </div>
                            <p className="upload-image-p">
                                <span className="primary-text">Click to Upload</span>
                                <span className="secondary-text">&nbsp;or drag and drop</span>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ImageDropzone;