import React, {useState} from "react";
import "./SearchHelper.scss";
import {usePopper} from "react-popper";

const SearchHelper = () => {
    const [dropdownRef, setDropdownRef] = useState(null);
    const [helperRef, setHelperRef] = useState(null);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const {styles, attributes} = usePopper(helperRef, dropdownRef, {
        placement: "bottom"
    });

    return (
        <div className="search-helper"
             ref={setHelperRef}
             onMouseEnter={() => setIsDropdownVisible(true)}
             onMouseLeave={() => setIsDropdownVisible(false)}>
            <img className="icon" src="/icons/help_icon.svg" alt="Search Icon"/>
            {isDropdownVisible && <div ref={setDropdownRef}
                                       style={styles.popper}
                                       {...attributes.popper}
                                       className="dropdown">
                <div className="tip">
                    To find by chat type: <code>in:#ExampleChat</code>
                </div>
                <div className="tip">
                    To find by author type: <code>by:@John Doe</code>
                </div>
                <div className="tip">
                    To find by date before type: <code>before:DD.MM.YYYY</code>
                </div>
                <div className="tip">
                    To find by date after type: <code>after:DD.MM.YYYY</code>
                </div>
            </div>}
        </div>
    )
}

export default SearchHelper;