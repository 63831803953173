import React, { useState, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { usePopper } from "react-popper";
import EmojiPicker from "emoji-picker-react";
import { EditorState, Modifier } from "draft-js";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dataHeader, post } from "../../api";
import { setCall, setCallModalOpen } from "../../store/callSlice";
import "./ChatInput.scss";
import TextMentionInput from "../text-mention-input/TextMentionInput";
import { convertFromJson } from "../mention/mentionUtil";

const ChatInput = ({ chatName, projectName, chatUsers, userImageUrls }) => {
    const { userId, chatId } = useParams();
    const callName = useSelector(state => state.call.callName);

    const [files, setFiles] = useState([]);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [popperElement, setPopperElement] = useState(null);
    const [referenceElement, setReferenceElement] = useState(null);
    const dispatch = useDispatch();
    const emojiPopperRef = useRef();

    const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
        placement: "top-start",
    });

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const onSubmit = () => {
        const { message, mentions, json } = convertFromJson(editorState);

        if (message !== "" || files.length > 0) {
            post(`chats/${chatId}/messages`, {
                user_id: userId,
                chat_id: chatId,
                content: JSON.stringify(json),
            }).then((messageResponse) => {
                if (files.length > 0) {
                    const payloadBody = new FormData();
                    files.forEach((file) => {
                        payloadBody.append("documents", file);
                    });
                    post(`message/${messageResponse.id}/attachments`, payloadBody, dataHeader);
                }

                post(`chats/${chatId}/mentions/${messageResponse.id}`, {
                    mentions: mentions,
                });

                setFiles([]);
                const newEditorState = EditorState.moveFocusToEnd(EditorState.createEmpty());
                setEditorState(newEditorState);
            });
        }
    };

    useEffect(() => {
        if (showEmojiPicker && update) {
            update();
        }
    }, [showEmojiPicker, update]);

    const onDrop = (acceptedFiles) => {
        const newFiles = acceptedFiles.filter(
            (file) => !files.some((existingFile) => existingFile.name === file.name)
        );
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        noClick: true,
    });

    const removeFile = (fileName) => {
        setFiles(files.filter((file) => file.name !== fileName));
    };

    const toggleEmojiPicker = () => {
        setShowEmojiPicker((prev) => !prev);
    };

    const onEmojiClick = (emojiObject) => {
        const contentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();
        const contentStateWithEmoji = Modifier.insertText(
            contentState,
            selectionState,
            emojiObject.emoji
        );
        const newEditorState = EditorState.push(
            editorState,
            contentStateWithEmoji,
            "insert-characters"
        );
        setEditorState(newEditorState);
        setShowEmojiPicker(false);
    };

    const createOrJoinCall = () => {
        if (callName) {
            dispatch(setCallModalOpen(true));
        } else {
            dispatch(setCall({
                callName: `${chatName}${projectName ? ` - ${projectName}` : ""}`,
                callChannelId: chatId,
                userId: userId,
            }));
        }
    };

    const handleClickOutside = (event) => {
        if (emojiPopperRef.current && !emojiPopperRef.current.contains(event.target) && !referenceElement.contains(event.target)) {
            setShowEmojiPicker(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [referenceElement]);

    return (
        <div className="chat-input">
            <div {...getRootProps({ className: "dropzone" })} className="chat-input-field">
                <TextMentionInput
                    editorState={editorState}
                    setEditorState={setEditorState}
                    chatUsers={chatUsers}
                    userImageUrls={userImageUrls}
                    onSubmit={onSubmit}
                />
                <input {...getInputProps()} style={{ display: "none" }} />
            </div>
            <div className="chat-buttons">
                <img
                    src="/icons/emoji_icon.svg"
                    alt="Emoji"
                    className="button emoji"
                    ref={setReferenceElement}
                    onClick={toggleEmojiPicker}
                />
                {showEmojiPicker && (
                    <div ref={(element) => { setPopperElement(element); emojiPopperRef.current = element; }} style={styles.popper} {...attributes.popper} className="emoji-popper">
                        <EmojiPicker onEmojiClick={onEmojiClick} />
                    </div>
                )}
                {files.length > 0 && (
                    <div className="file-preview">
                        {files.map((file) => (
                            <div key={file.name} className="file-item">
                                {file.name}
                                <img
                                    src="/icons/remove_file_icon.svg"
                                    alt="Remove file"
                                    onClick={() => removeFile(file.name)}
                                    className="remove-button"
                                />
                            </div>
                        ))}
                    </div>
                )}
                <div className="submits">
                    <button className="button primary" onClick={createOrJoinCall}>
                        <img src="/icons/call_icon.svg" alt="Call Icon" />
                        Call
                    </button>
                    <button type="submit" className="button primary" onClick={onSubmit}>
                        <img src="/icons/send_icon.svg" alt="Send Icon" />
                        Send
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ChatInput;