import React, {useState, useEffect} from "react";
import "./Navbar.scss";
import NavbarItem from "./NavbarItem";
import {useLocation, useNavigate} from "react-router-dom";
import Image from "../image/Image";
import {usePopper} from "react-popper";
import {useDispatch, useSelector} from "react-redux";
import {logoutUser} from "../../store/userSlice";
import {roles} from "../../constants";
import CallInfo from "./CallInfo";
import {closeCall} from "../../store/callSlice";
import ElasticSearch from "../elasticsearch/ElasticSearch";
import SearchHelper from "../elasticsearch/SearchHelper";

const Navbar = () => {
    const location = useLocation()
    const user = useSelector(state => state.user.user);
    const isAdmin = user.role === roles.ADMIN;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [isLogoutVisible, setIsLogoutVisible] = useState(false);
    const callName = useSelector(state => state.call.callName);
    const {styles, attributes} = usePopper(referenceElement, popperElement, {
        placement: "bottom-end",
    });

    const handleClickOutside = (event) => {
        if (
            referenceElement &&
            popperElement &&
            !referenceElement.contains(event.target) &&
            !popperElement.contains(event.target)
        ) {
            setIsLogoutVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [referenceElement, popperElement]);

    useEffect(() => {
        dispatch(closeCall());
    }, []);

    return (
        <nav className="navbar">
            <ul className="navbar-nav">
                {isAdmin && (
                    <>
                        <NavbarItem
                            to="/users"
                            iconPath="/icons/users_icon.svg"
                            alt="Users Icon"
                            active={location.pathname.startsWith("/user")}
                        >
                            Users
                        </NavbarItem>
                        <NavbarItem
                            to="/projects"
                            iconPath="/icons/projects_icon.svg"
                            alt="Projects Icon"
                            active={location.pathname.startsWith("/project")}
                        >
                            Projects
                        </NavbarItem>
                    </>
                )}
                <NavbarItem to={`/chats/${user.id}`} iconPath="/icons/chat_icon.svg" alt="Chats Icon"
                            active={location.pathname.startsWith("/chats")}>
                    Chats
                </NavbarItem>
            </ul>
            <div className="search-wrapper">
                <ElasticSearch/>
                <SearchHelper/>
            </div>
            <div className="right">
                {callName && <CallInfo/>}
                <div
                    onClick={() => setIsLogoutVisible((prev) => !prev)}
                    ref={setReferenceElement}
                >
                    <Image
                        imageId={user.document_id}
                        name={`${user.name} ${user.surname}`}
                        color={user.color}
                        className="image"
                    />
                    {isLogoutVisible && (
                        <div
                            ref={setPopperElement}
                            className="popup-menu"
                            style={{...styles.popper}}
                            {...attributes.popper}
                        >
                            <div
                                className="item"
                                onClick={() => {
                                    dispatch(logoutUser())
                                    navigate("/login");
                                }}
                            >
                                Logout
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default Navbar;