import React, {useEffect, useRef, useState} from "react";
import "./ChatMessages.scss";
import Message from "../../../../../components/message/Message";
import useWebSocket from "../../../../../websocket/useWebSocket";
import {get} from "../../../../../api";
import {useLocation, useParams} from "react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";

const ChatMessages = ({chatUsers, userImageUrls}) => {
    const {chatId} = useParams();
    const location = useLocation();
    const [messages, setMessages] = useState([]);
    const {lastMessage, lastReaction, lastAttachment} = useWebSocket();
    const [isLoading, setIsLoading] = useState(false);
    const [isEnd, setIsEnd] = useState(false);
    const [searchedMessageId, setSearchedMessageId] = useState(null);
    const messageRefs = useRef({});
    const [scrolled, setScrolled] = useState(false);
    const [pageTop, setPageTop] = useState(null);
    const [pageBottom, setPageBottom] = useState(null);
    const [userHasScrolled, setUserHasScrolled] = useState(false);
    const scrollableRef = useRef(null);

    const loadMessages = (newPage = 0, appendToBottom = false, pageSize = 20) => {
        setIsLoading(true);
        get(`chats/${chatId}/messages?page=${newPage}&size=${pageSize}`)
            .then(newMessages => {
                if (newMessages.length < pageSize) {
                    setIsEnd(true);
                }

                const scrollableElement = scrollableRef.current;
                const messageToDrawToRef = messages[0] ? messageRefs.current[messages[0].id] : null;

                setMessages(prev => {
                    if (appendToBottom) {
                        setPageBottom(newPage);
                        return prev ? [...newMessages, ...prev] : newMessages;
                    } else {
                        setPageTop(newPage);
                        return prev ? [...prev, ...newMessages] : newMessages;
                    }
                });

                setTimeout(() => {
                    if (appendToBottom && scrollableElement && messageToDrawToRef && userHasScrolled) {
                        scrollableElement.scrollTop = messageToDrawToRef.offsetTop - 660;
                    }
                }, 100);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleLoadMoreBelow = () => {
        if (!isLoading && pageBottom >= 1) {
            loadMessages(pageBottom - 1, true);
        }
    };

    const handleLoadMoreAbove = () => {
        if (!isLoading && !isEnd) {
            loadMessages(pageTop + 1, false);
        }
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const searchedMessageId = searchParams.get("messageId");
        const messagePage = searchParams.get("page");
        setSearchedMessageId(searchedMessageId);

        setMessages([]);
        setIsEnd(false);
        setPageBottom(messagePage ? parseInt(messagePage) : 0);
        setPageTop(messagePage ? parseInt(messagePage) : 0);
        setIsLoading(true);
        setScrolled(false);
        setUserHasScrolled(false);

        loadMessages(messagePage ? parseInt(messagePage) : 0);
    }, [location]);

    useEffect(() => {
        if (lastMessage && lastMessage.chat_id === chatId && !isLoading && pageBottom === 0) {
            setMessages(prevMessages => {
                const messageInMessages = prevMessages.find(message => message.id === lastMessage.id);
                if (!messageInMessages) {
                    return [{...lastMessage, reactions: [], attachments: []}, ...prevMessages];
                } else if (lastMessage.content === "") {
                    return prevMessages.filter(message => message.id !== lastMessage.id);
                } else {
                    return prevMessages.map(message => message.id === lastMessage.id ? {
                        ...lastMessage,
                        reactions: message.reactions,
                        attachments: message.attachments
                    } : message);
                }
            });
        }
    }, [lastMessage, isLoading, chatId]);

    useEffect(() => {
        const foundMessage = lastReaction ? messages.find(message => message.id === lastReaction.message_id) : null;
        if (foundMessage && foundMessage.reactions) {
            if (lastReaction.code === "") {
                setMessages(prev => prev.map(message => message.id === lastReaction.message_id ? {
                    ...message,
                    reactions: message.reactions.filter(reaction => reaction.id !== lastReaction.id),
                    attachments: message.attachments
                } : message));
            } else {
                setMessages(prev => prev.map(message => message.id === lastReaction.message_id ? {
                    ...message,
                    reactions: [...message.reactions, lastReaction],
                    attachments: message.attachments
                } : message));
            }
        }
    }, [lastReaction]);

    useEffect(() => {
        const foundMessage = lastAttachment ? messages.find(message => message.id === lastAttachment.message_id) : null;
        if (foundMessage && foundMessage.attachments) {
            if (lastAttachment.document_name === "") {
                setMessages(prev => prev.map(message => message.id === lastAttachment.message_id ? {
                    ...message,
                    reactions: message.reactions,
                    attachments: message.attachments.filter(attachment => attachment.id !== lastAttachment.id)
                } : message));
            } else {
                setMessages(prev => prev.map(message => message.id === lastAttachment.message_id ? {
                    ...message,
                    reactions: message.reactions,
                    attachments: [...message.attachments, lastAttachment]
                } : message));
            }
        }
    }, [lastAttachment]);

    const [sentryRefTop] = useInfiniteScroll({
        loading: isLoading,
        hasNextPage: !isEnd,
        onLoadMore: handleLoadMoreAbove,
        disabled: !userHasScrolled,
        threshold: 0.8,
    });

    const [sentryRefBottom] = useInfiniteScroll({
        loading: isLoading,
        hasNextPage: pageBottom !== 0,
        onLoadMore: handleLoadMoreBelow,
        disabled: !userHasScrolled,
        threshold: 0.8,
    });

    useEffect(() => {
        const scrollableElement = scrollableRef.current;

        const handleUserScroll = () => {
            setUserHasScrolled(true);
        };

        if (scrollableElement) {
            scrollableElement.addEventListener("wheel", handleUserScroll);
            scrollableElement.addEventListener("touchmove", handleUserScroll);
            scrollableElement.addEventListener("mousedown", handleUserScroll);
        }

        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener("wheel", setUserHasScrolled(true));
                scrollableElement.removeEventListener("touchmove", setUserHasScrolled(true));
                scrollableElement.removeEventListener("mousedown", setUserHasScrolled(true));
            }
        };
    }, []);

    useEffect(() => {
        if (!isLoading && searchedMessageId && messages.length > 0 && !scrolled && !userHasScrolled) {
            setTimeout(() => {
                const messageElement = messageRefs.current[searchedMessageId];
                const scrollableElement = scrollableRef.current;

                if (messageElement && scrollableElement) {
                    scrollableElement.scrollTop = messageElement.offsetTop - scrollableElement.offsetTop;
                    setScrolled(true);
                }
            }, 100);
        }
    }, [isLoading, searchedMessageId, messages, scrolled, userHasScrolled, messages]);

    return (
        <div className="chat-messages" id="scrollable" ref={scrollableRef}>
            <div ref={sentryRefBottom}></div>
            {messages.map((message) => (
                <Message
                    key={message.id}
                    message={message}
                    chatUsers={chatUsers}
                    sender={chatUsers.find(user => user.id === message.user_id)}
                    userImageUrls={userImageUrls}
                    isSearched={searchedMessageId === message.id}
                    ref={el => messageRefs.current[message.id] = el}
                />
            ))}
            <div ref={sentryRefTop}></div>
            {isLoading && <div className="info">Loading...</div>}
            {isEnd && <div className="info">You've reached the end!</div>}
        </div>
    );
};

export default ChatMessages;