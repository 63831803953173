import React, {useEffect, useState} from "react";
import "./ChatUsers.scss";
import {useParams} from "react-router-dom";
import {get} from "../../../../../api";
import ImageTriplet from "../../../../../components/image/ImageTriplet";


const ChatUsers = ({userImageUrls}) => {
    const {chatId} = useParams();
    const [activeUsers, setActiveUsers] = useState([]);

    useEffect(() => {
        get(`chat/${chatId}/users/active`)
            .then(users => {
                setActiveUsers(users);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, [chatId]);



    return (
        <div className="chat-users">
            <ImageTriplet users={activeUsers} userImageUrls={userImageUrls}/>
            <div>
                {`${activeUsers.length}`}
            </div>
        </div>
    );
}

export default ChatUsers;