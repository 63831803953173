import React from "react";
import {useDispatch, useSelector} from "react-redux";
import JitsiCallModal from "../../jitsi/JitsiCallModal";
import "./CallInfo.scss";
import {setCallModalOpen} from "../../store/callSlice";


const CallInfo = () => {
    const callName = useSelector(state => state.call.callName);
    const dispatch = useDispatch();

    const openCallModal = () => {
        dispatch(setCallModalOpen(true));
    }

    return (
        <div>
            <div className="call-info" style={{display: callName ? "" : "none"}} onClick={openCallModal}>
                <img src="/icons/call_icon_dark.svg" alt="call-info" className="icon" />
                {`Ongoing Call: ${callName}`}
            </div>
            <JitsiCallModal/>
        </div>
    )
}

export default CallInfo;