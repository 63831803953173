import React, {useState, useEffect} from "react";
import {get, dataHeader} from "../../api";
import "./Image.scss";

const Image = ({imageId, name, color, radius = 40, imageSource = null}) => {
    const [imageSrc, setImageSrc] = useState(imageSource);

    useEffect(() => {
            if (imageId && !imageSrc) {
                get(`documents/${imageId}`, dataHeader)
                    .then(image => {
                        const urlCreator = window.URL || window.webkitURL;
                        const imageUrl = urlCreator.createObjectURL(image);
                        setImageSrc(imageUrl);
                    })
                    .catch(error => {
                        console.error("There was an error fetching the image!", error);
                    });
            }

        },[imageId]);

    const getInitials = (name) => {
        const nameParts = name.split(" ");
        let initials = nameParts[0].charAt(0);
        if (nameParts.length > 1) {
            initials += nameParts[1].charAt(0);
        }
        return initials;
    };


    return (
        <div className="image" style={{backgroundColor: imageId ? "transparent" : color, width: radius, height: radius, fontSize: radius/2}}>
            {imageSrc ? (
                <img src={imageSrc} alt={name}/>
            ) : (
                <div>{getInitials(name)}</div>
            )}
        </div>
    );
};

export default Image;
