import React from "react"
import Image from "../../../image/Image";
import "./ESUser.scss";
import {highlightQuery} from "../../elasticsearchUtil";

const ESUser = ({user, query}) => {
    return (
        <div key={user.id} className="esuser">
            <div className="content">{highlightQuery(`${user.name} ${user.surname}`, query)}</div>
            <div className="content">
                <Image imageId={user.image_id} name={`${user.name} ${user.surname}`} color={user.color} radius={20}/>
            </div>
        </div>
    )
}

export default ESUser