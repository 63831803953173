import React from "react";
import "./ESMessage.scss";
import { highlightQuery } from "../../elasticsearchUtil";
import {get} from "../../../../api";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import dayjs from "dayjs";

const ESMessage = ({ message, query }) => {
    const navigate = useNavigate();
    const user = useSelector(state => state.user.user);
    const text = JSON.parse(message.content).blocks[0].text;
    const formattedDate = dayjs(message.created_at).format("YYYY/MM/DD HH:mm");

    const findMessage = () => {
        get(`chats/${message.chat_id}/messages/${message.id}?size=20`)
            .then(page => {
                navigate(`/chats/${user.id}/${message.chat_id}?messageId=${message.id}&page=${page}`);
            })
            .catch(error => {
                console.error("Error fetching message:", error);
            });
    }

    return (
        <div key={message.id} className="esmessage" onClick={findMessage}>
            <div className="content">
                {highlightQuery(text, query, true)}
            </div>
            <div className="info">
                <div className="tag">In:</div>
                <div className="content">{message.chat_name}</div>
            </div>
            <div className="info">
                <div className="tag">By:</div>
                <div className="content">{message.author}</div>
            </div>
            <div className="info">
                <div className="tag">Created at:</div>
                <div className="content">{formattedDate}</div>
            </div>
        </div>
    );
};

export default ESMessage;