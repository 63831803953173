import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {login} from "../api";

export const loginUser = createAsyncThunk(
    "user/loginUser",
    async (loginData, {rejectWithValue}) => {
        try {
            return await login(loginData);
        } catch (error) {
            console.error("There was an error logging in the user!", error);
            return rejectWithValue(error.response.data);
        }
    }
);

const userSlice = createSlice({
    name: "user",
    initialState: {
        user: null,
        token: null,
    },
    reducers: {
        logoutUser: (state) => {
            state.token = null;
            state.user = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.fulfilled, (state, action) => {
                state.token = action.payload.token;
                state.user = action.payload.user;
            })
    }
});

export const {logoutUser} = userSlice.actions;

export default userSlice.reducer;