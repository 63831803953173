import appConfig from "../config/applicationConfiguration";
import ReconnectingWebSocket from "reconnecting-websocket";

class WebSocketService {
    constructor(userId, onMessageReceived) {
        this.onMessageReceived = onMessageReceived;
        this.userId = userId;
        this.socket = null;
    }

    connect() {
        const token = JSON.parse(localStorage.getItem("state"))?.user?.token;

        const socketUrl = `${appConfig.wsUrl}?token=${token}`;

        this.socket = new ReconnectingWebSocket(socketUrl);

        this.socket.onmessage = (message) => {
            const data = JSON.parse(message.data);
            this.onMessageReceived(data);
        };

        this.socket.onerror = (error) => {
            console.error("WebSocket error:", error);
        };
    }

    close() {
        if (this.socket) {
            this.socket.close();
        }
    }
}

export default WebSocketService;