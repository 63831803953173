const customSelectStyles = (error) => ({
    control: (provided) => ({
        ...provided,
        width: "100%",
        minHeight: "40px",
        borderColor: error ? "red" : "#D9D9D9B2",
        backgroundColor: error ? "#FF00001A" : "white",
        flexWrap: "wrap",
        "&:hover": {},
        boxShadow: "none",
    }),

    multiValue: (provided) => ({
        ...provided,
        borderRadius: "10px",
        display: "flex",
        flexWrap: "wrap",
    }),

    multiValueRemove: (provided) => ({
        ...provided,
        cursor: "pointer",
        "&:hover": {
            color: "white",
            borderRadius: "0 10px 10px 0",
        },
    }),

    valueContainer: (provided) => ({
        ...provided,
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        overflow: "visible",
    }),

    singleValue: (provided) => ({
        ...provided,
        height: "100%",
        display: "flex",
        alignItems: "center",
        padding: "0"
    }),

    menu: (provided) => ({
        ...provided,
        width: "100%",
        borderRadius: "10px",
        boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
        border: "1px solid #D9D9D9B2",
        maxHeight: "200px",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        zIndex: 1000,
    }),


    placeholder: (provided) => ({
        ...provided,
        color: error ? "#FF000099" : "#AAAAAA",
        fontSize: "14px",
    }),
});

export default customSelectStyles;
