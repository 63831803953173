import {Link} from "react-router-dom";
import React from "react";
import "./NavbarItem.scss";

const NavbarItem = ({ to, iconPath, alt, active, children }) => {
    return (
        <li>
            <Link to={to} className={active ? "navbar-item active" : "navbar-item"}>
                <img src={iconPath} alt={alt} className="icon" />
                {children}
            </Link>
        </li>
    )
}

export default NavbarItem;