import React from "react"
import {highlightQuery} from "../../elasticsearchUtil";
import "./ESAttachment.scss";
import {get} from "../../../../api";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";

const ESAttachment = ({attachment, query}) => {
    const navigate = useNavigate();
    const user = useSelector(state => state.user.user);
    const formattedDate = dayjs(attachment.created_at).format("YYYY/MM/DD HH:mm");

    const findAttachment = () => {
        get(`chats/${attachment.chat_id}/messages/${attachment.message_id}?size=20`)
            .then(page => {
                navigate(`/chats/${user.id}/${attachment.chat_id}?messageId=${attachment.message_id}&page=${page}`);
            })
            .catch(error => {
                console.error("Error fetching message:", error);
            });
    }

    return (
        <div key={attachment.id} className="esattachment" onClick={findAttachment}>
            <div className="content">
                {highlightQuery(attachment.document_name, query, true)}
            </div>
            <div className="info">
                {attachment.miniature && <img
                    className="miniature"
                    src={`data:image/jpeg;base64,${attachment.miniature}`}
                    alt="Miniature"
                />}
            </div>
            <div className="info">
                <div className="tag">In:</div>
                <div className="content">{attachment.chat_name}</div>
            </div>
            <div className="info">
                <div className="tag">By:</div>
                <div className="content">{attachment.author}</div>
            </div>
            <div className="info">
                <div className="tag">Created at:</div>
                <div className="content">{formattedDate}</div>
            </div>

        </div>
    )
}

export default ESAttachment