import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import callReducer from "./callSlice";

const loadState = () => {
    try {
        const serializedState = localStorage.getItem("state");
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

const store = configureStore({
    reducer: {
        user: userReducer,
        call: callReducer
    },
    preloadedState: loadState()
});

store.subscribe(() => {
    try {
        const serializedState = JSON.stringify(store.getState());
        localStorage.setItem("state", serializedState);
    } catch {
        console.error("There was an error saving the state!")
    }
});

export default store;