import React from "react";
import "./AllItemsModal.scss";
import Modal from "react-modal";
import ESAttachment from "./items/attachment/ESAttachment";
import ESMessage from "./items/message/ESMessage";
import ESUser from "./items/user/ESUser";

const AllItemsModal = ({type, items, query, isModalOpen, setIsModalOpen}) => {

    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={() => {
                setIsModalOpen(false
                )
            }}
            className="all-items-modal modal"
            overlayClassName="overlay"
            contentLabel="All Items Modal"
        >
            <header className="header">
                <div className="text">
                    {type}
                </div>
            </header>
            <main className="main">
                {type === "Attachments" && items.map((item, index) => (
                    <ESAttachment attachment={item} query={query} key={index}/>
                ))
                }
                {
                    type === "Users" && items.map((item, index) => (
                        <ESUser user={item} query={query} key={index}/>
                    ))
                }
                {
                    type === "Messages" && items.map((item, index) => (
                        <ESMessage message={item} query={query} key={index}/>
                    ))
                }
            </main>
        </Modal>
    );
}

export default AllItemsModal;
