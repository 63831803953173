import React from "react";
import "./Card.scss";
import {Link} from "react-router-dom";

const Card = ({children, link, onDelete}) => {
    return (
        <div className="card">
            <div className="content">
                {children}
            </div>
            <div className="action-buttons">
                <Link to={link}>
                    <img src="/icons/edit_icon.svg" alt="Edit Icon"/>
                </Link>
                <button>
                    <img src="/icons/delete_icon.svg" alt="Delete Icon" onClick={onDelete}/>
                </button>
            </div>
        </div>
    )
}

export default Card;