import React from "react";
import ChatsSidebar from "./ChatsSidebar/ChatsSidebar";
import Chat from "./Chat/Chat";
import "./Chats.scss";


const Chats = () => {
    return (
        <div className="chats">
            <ChatsSidebar/>
            <Chat/>
        </div>
    )
}

export default Chats;