import React, {useState, useEffect} from "react";
import {get} from "../../../api";
import UserCardActions from "../../../components/card/UserCardActions";
import {Link} from "react-router-dom";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";


const Users = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
            get("users")
                .then(users => {
                    setUsers(users);
                });
        }, []);

    const onDelete = (id) => {
        setUsers(users.filter(user => user.id !== id));
    }

    return (
        <>
            <header>
                <Breadcrumbs icon="/icons/users_icon.svg" links={[{to: "/users", label: "Users"}]}/>
                <div className="header-button">
                    <Link to="/user" className="button primary">
                        <img src="/icons/plus_icon_light.svg" className="icon" alt="Add"/>
                        Add new
                    </Link>
                </div>
            </header>
            <main>
                <div className="main-info">
                    <div>Users</div>
                    <div>Actions</div>
                </div>
                {users.map(user => (
                    <UserCardActions key={user.id} user={user} onDelete={onDelete}/>
                ))}
            </main>
            <footer>
                <div/>
            </footer>
        </>
    )
}

export default Users;