import React from "react";

export const regexPatterns = {
    "in:#": /in:#\S+/g,
    "by:@": /by:@\S+/g,
    "before": /before:\d{2}\.\d{2}\.\d{4}/g,
    "after": /after:\d{2}\.\d{2}\.\d{4}/g
};


export const highlightQuery = (text, query, truncate = false) => {
    const MAX_LENGTH = 5;
    const cleanedQuery = query
        .replace(regexPatterns["in:#"], "")
        .replace(regexPatterns["by:@"], "")
        .replace(regexPatterns["before"], "")
        .replace(regexPatterns["after"], "")
        .trim();

    const queryIndex = text.toLowerCase().indexOf(cleanedQuery.toLowerCase());

    if (queryIndex === -1) {
        return (
            <>
                {text}
            </>
        )
    }


    const beforeQuery = text.substring(0, queryIndex);
    const queryText = text.substring(queryIndex, queryIndex + cleanedQuery.length);
    const afterQuery = text.substring(queryIndex + cleanedQuery.length);

    const truncateText = (text, isBeforeQuery) => {
        if (text.length <= MAX_LENGTH) {
            return text;
        }
        return isBeforeQuery
            ? `...${text.substring(text.length - MAX_LENGTH)}`
            : `${text.substring(0, MAX_LENGTH)}...`;
    };

    return (
        <>
            {truncate ? <>{truncateText(beforeQuery, true)}</> : beforeQuery}
            {queryText && <b>{queryText}</b>}
            {truncate ? <>{truncateText(afterQuery, false)}</> : afterQuery}
        </>
    );
};

export const findWithRegex = (regex, contentBlock, callback) => {
    const text = contentBlock.getText();
    let matchArr, start;
    while ((matchArr = regex.exec(text)) !== null) {
        start = matchArr.index;
        callback(start, start + matchArr[0].length);
    }
};
