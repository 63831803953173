import React, {useState, useEffect} from "react";
import {get} from "../../../api";
import {Link} from "react-router-dom";
import ProjectCardActions from "../../../components/card/ProjectCardActions";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";

const Projects = () => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
            get("projects")
                .then(projects => {
                    setProjects(projects);
                });
        }, []);

    const onDelete = (id) => {
        setProjects(projects.filter(project => project.id !== id));
    }

    return (
        <>
            <header>
                <Breadcrumbs icon="/icons/projects_icon.svg" links={[{to: "/projects", label: "Projects"}]}/>
                <div className="header-button">
                    <Link to="/project" className="button primary">
                        <img src="/icons/plus_icon_light.svg" className="icon" alt="Add"/>
                        Add new
                    </Link>
                </div>
            </header>
            <main>
                <div className="main-info">
                    <div>Projects</div>
                    <div>Actions</div>
                </div>
                {projects.map(project => (
                    <ProjectCardActions key={project.id} project={project} onDelete={onDelete}/>
                ))}
            </main>
            <footer>
                <div/>
            </footer>
        </>
    )
}

export default Projects;