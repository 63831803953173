import {dataHeader, post, put} from "../../api";


export const saveImage = (image) => {
    const payloadBody = {
        document: image
    }
    return post("documents", payloadBody, dataHeader)
}

export const updateImage= (image, id) => {
    const payloadBody = {
        document: image
    }
    return put(`documents/${id}`, payloadBody, dataHeader)
}

export const saveOrUpdateImage = (image, id) => {
    return id ? updateImage(image, id) : saveImage(image)
}

export const saveUser = (userBody) => {
    return post("users", userBody)
}

export const updateUser = (userBody, id) => {
    return put(`users/${id}`, userBody)
}

export const saveProject = (projectBody, users) => {
    const addedUsersBody = getIdUsersJson(users)
    const combinedBody = getCombinedProjectJson(projectBody, addedUsersBody);

    return post("projects", combinedBody)
}

export const updateProject = (projectBody, users, id) => {
    const addedUsersBody = getIdUsersJson(users)
    const combinedBody = getCombinedProjectJson(projectBody, addedUsersBody);
    return put(`projects/${id}`, combinedBody)
}

export const saveChat = (chatBody, users) => {
    const addedUsersBody = getIdUsersJson(users)
    const combinedBody = getCombinedChatJson(chatBody, addedUsersBody);
    return post("chats", combinedBody)
}

export const getIdUsersJson = (formUsers) => {
    let usersIdNameArray = [];
    formUsers.forEach(user => {
        const addedUserBody = {
            id: user.id,
            name: user.name
        };
        usersIdNameArray.push(addedUserBody);
    });
    return {
        users: usersIdNameArray
    }
}

const getCombinedProjectJson = (projectBody, usersBody) => {
    return {
        ...projectBody,
        "users": usersBody.users
    }
}

const getCombinedChatJson = (chatBody, usersBody) => {
    return {
        ...chatBody,
        "users": usersBody.users
    }
}

