import {post, remove} from "../../api";

const checkIfReactionExists = (reactions, emojiCode, userId) => {
    return Object.values(reactions).some(reaction => reaction.code === emojiCode && reaction.user_id === userId);
}

export const handleAddOrRemoveReaction = (reactions, emojiCode, chatId, userId, messageId) => {
    if (checkIfReactionExists(reactions, emojiCode, userId)) {
        const currentReaction = Object.values(reactions).find(reaction => reaction.code === emojiCode && reaction.user_id === userId);
        remove(`/message/${messageId}/reactions/${currentReaction.id}`)
    } else {
        const reactionBody = {
            "chat_id": chatId,
            "user_id": userId,
            "message_id": messageId,
            "code": emojiCode,
        }

        post(`/message/${messageId}/reactions`, reactionBody)
    }
}