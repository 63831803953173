import React, {useState} from "react";
import {remove} from "../../api";
import Modal from "react-modal";
import Card from "./Card";
import Image from "../image/Image";

const UserCardActions = ({user, onDelete}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleUserDelete = () => {
        setModalIsOpen(true);
    };

    const deleteUserAndCloseModal = () => {
        remove(`users/${user.id}`)
            .then(() => {
                onDelete(user.id);
            })
            .catch(error => {
                console.error("There was an error deleting the user!", error);
            });
        setModalIsOpen(false);
    };

    return (
        <>
            <Card link={`/user/${user.id}`} onDelete={handleUserDelete}>
                <Image imageId={user.document_id} name={`${user.name} ${user.surname}`} color={user.color}/>
                <div className="item-name">
                    {user.name} {user.surname}
                </div>
            </Card>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                className="modal"
                overlayClassName="overlay"
                contentLabel="Delete User Confirmation"
            >
                <div className="modal-content">
                    <p>Are you sure you want to delete this user?</p>
                    <div>
                        <button onClick={deleteUserAndCloseModal} className="button primary">Yes</button>
                        <button onClick={() => setModalIsOpen(false)} className="button secondary">No</button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default UserCardActions;
