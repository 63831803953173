import React, { useEffect, useState } from "react";
import "./ChatCall.scss";
import useWebSocket from "../../websocket/useWebSocket";
import { useParams } from "react-router-dom";
import { get } from "../../api";
import ImageTriplet from "../image/ImageTriplet";
import Image from "../image/Image";

const ChatCall = ({userImageUrls }) => {
    const { chatId } = useParams();
    const [usersInCall, setUsersInCall] = useState([]);
    const { lastCallAction } = useWebSocket();

    useEffect(() => {
        get(`call/${chatId}/users`).then(callUsers => {
            setUsersInCall(callUsers);
        });
    }, [chatId]);

    useEffect(() => {
        if (lastCallAction && lastCallAction.chat_id === chatId) {
            const userInCall = usersInCall.find(user => user.id === lastCallAction.user.id);
            if (!userInCall && lastCallAction.in_call) {
                setUsersInCall(prev => [...prev, lastCallAction.user]);
            } else {
                setUsersInCall(prev => prev.filter(user => user.id !== lastCallAction.user.id));
            }
        }
    }, [lastCallAction]);

    return (
        <>
            {usersInCall.length > 0 && (
                <div className="chat-call">
                    Users in call: {`${usersInCall.length}`}
                    <ImageTriplet users={usersInCall} userImageUrls={userImageUrls} />
                </div>
            )}
        </>
    );
};

export default ChatCall;