import React from "react";
import "../../Form.scss";
import "./RadioInputOption.scss";


const RadioInputOption = ({ label, name, value, formik, ...props }) => {
    return (
        <div className="radio-input-option">
            <input
                type="radio"
                name={name}
                value={value}
                checked={formik.values[name] === value}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...props}
            />
            <label className="label" htmlFor={props.id || name}>
                {label}
            </label>
        </div>
    );
}

export default RadioInputOption;