import React from "react";
import "./Attachment.scss";
import {dataHeader, get, remove} from "../../api";

const Attachment = ({attachment, isEditing}) => {
    const getIcon = () => {
        const fileFormat = attachment.document_name.split(".").pop().toLowerCase();

        switch (fileFormat) {
            case "jpg":
            case "jpeg":
            case "png":
            case "webp":
                return null;
            case "mp4":
            case "mov":
            case "avi":
            case "webm":
                return <img className="type" src="/icons/play_icon.svg" alt="Video"/>;
            case "gif":
                return <img className="type" src="/icons/gif_icon.svg" alt="GIF"/>;
            case "txt":
                return <img className="type" src="/icons/text_icon.svg" alt="Text"/>;
            case "pdf":
                return <img className="type" src="/icons/pdf_icon.svg" alt="PDF"/>;
            case "doc":
            case "docx":
                return <img className="type" src="/icons/word_icon.svg" alt="Word"/>;
            case "xls":
            case "xlsx":
                return <img className="type" src="/icons/excel_icon.svg" alt="Excel"/>;
            case "ppt":
            case "pptx":
                return <img className="type" src="/icons/powerpoint_icon.svg" alt="PowerPoint"/>;
            default:
                return <img className="type" src="/icons/file_icon.svg" alt="File"/>;
        }
    };

    const downloadAttachment = (event) => {
        event.preventDefault();
        get(`message/${attachment.message_id}/attachments/${attachment.id}`, dataHeader)
            .then((file) => {
                const urlCreator = window.URL || window.webkitURL;
                const fileUrl = urlCreator.createObjectURL(file);
                const a = document.createElement("a");
                a.href = fileUrl;
                a.download = attachment.document_name;
                a.click();
            })
            .catch((error) => {
                console.error("Error downloading attachment:", error);
            });
    }

    const deleteAttachment = (event) => {
        event.preventDefault();
        remove(`message/${attachment.message_id}/attachments/${attachment.id}`);
    }

    return (
        <div className="attachment">
            <div>{attachment.document_name}</div>
            <div className="miniature-container" onClick={downloadAttachment}>
                {attachment.miniature && (
                    <img
                        className="miniature"
                        src={`data:image/jpeg;base64,${attachment.miniature}`}
                        alt="Miniature"
                    />
                )}
                {getIcon()}
                {isEditing && <img className="delete" src="/icons/remove_file_icon.svg" alt="Delete"
                                   onMouseDown={deleteAttachment}/>}
            </div>
        </div>
    );
};

export default Attachment;