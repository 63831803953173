import React from "react";
import {Link} from "react-router-dom";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";


const UserActionWrapper = ({children, onSubmit, path, user}) => {
    return (
        <>
            <header>
                <Breadcrumbs icon="/icons/users_icon.svg" links={[{to: "/users", label: "Users"}, {to: path, label: `${user.name} ${user.surname}`}]}/>

                <div className="header-button">
                    <button type="button" className="button primary" onClick={onSubmit}>Save</button>
                    <Link to="/users" className="button secondary">Cancel</Link>
                </div>
            </header>
            <main>
                {children}
            </main>
        </>
    );
}

export default UserActionWrapper;