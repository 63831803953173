import React from "react";
import Select, {components} from "react-select";
import Image from "../../../../image/Image";
import customSelectStyles from "../CustomSelectStyles";
import "./UserSelect.scss";
import "../../../Form.scss";


const UserSelect = ({label, users, name, formik, required = true}) => {

    const mapToUserCard = (props) => {
        const user = props.data;
        return (<components.Option {...props}>
            <div className="dropdown-element">
                <Image imageId={user.document_id} name={`${user.name} ${user.surname}`} color={user.color}/>
                <div className="item-name">
                    {user.name} {user.surname}
                </div>
            </div>
        </components.Option>);
    };

    const handleChange = (selectedOptions) => {
        formik.setFieldValue(name, selectedOptions);
    };

    const isError = formik.errors[name] && formik.touched[name];

    return (
        <div className="user-select">
            <div className="row">
                <label className="label">
                    {required && <span className="required-asterisk">*</span>}
                    {label}
                </label>
                <Select
                    isMulti
                    options={users}
                    name={name}
                    className={"select"}
                    styles={customSelectStyles(isError)}
                    value={formik.values[name]}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={isError ? formik.errors[name] : "Select users..."}
                    components={{Option: mapToUserCard}}
                    getOptionLabel={(user) => `${user.name} ${user.surname}`}
                    getOptionValue={(user) => user.id}
                    isSearchable={true}
                    filterOption={(option, inputValue) => {
                        const user = option.data;
                        const searchValue = `${user.name} ${user.surname}`.toLowerCase();
                        return searchValue.includes(inputValue.toLowerCase());
                    }}
                />
            </div>
        </div>
    );
}

export default UserSelect;
