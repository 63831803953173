import React, { useState } from "react";
import Image from "./Image";
import "./ImageTriplet.scss";
import { usePopper } from "react-popper";

const ImageTriplet = ({ users, userImageUrls }) => {
    const [showUserList, setShowUserList] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: "bottom",
    });

    const handleMouseEnter = (e) => {
        setReferenceElement(e.currentTarget);
        setShowUserList(true);
    };

    const handleMouseLeave = () => {
        setShowUserList(false);
    };

    return (
        <div className="image-triplet">
            <div className="image-list" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {users.slice(0, Math.min(3, users.length)).map(user => (
                    <div key={user.id} className="img">
                        <Image imageId={user.document_id} name={`${user.name} ${user.surname}`} color={user.color}
                               radius={25} imageSource={userImageUrls[user.id]} />
                    </div>
                ))}
            </div>
            {showUserList && (
                    <div ref={setPopperElement} style={styles.popper} {...attributes.popper} className="user-list">
                    {users.map(user => (
                        <div key={user.id}>
                            {user.name} {user.surname}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default ImageTriplet;
