import "./App.scss";
import React from "react";
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import Users from "./view/admin/users/Users";
import Projects from "./view/admin/projects/Projects";
import Layout from "./components/layout/Layout";
import User from "./view/admin/users/User";
import Project from "./view/admin/projects/Project";
import Chats from "./view/user/chats/Chats";
import Login from "./view/login/Login";
import {roles} from "./constants";
import WebSocketProvider from "./websocket/WebSocketProvider";
import {useSelector} from "react-redux";

const App = () => {
    const user = useSelector(state => state.user.user)
    const defaultPath = user ? (user.role === roles.ADMIN ? "/users" : `/chats/${user.id}`) : "/login";

    return (
        <Router>
            <div className="app">
                {user ? (
                    <WebSocketProvider userId={user.id}>
                        <Routes>
                            <Route path="/users" element={<Layout><Users/></Layout>}/>
                            <Route path="/user/:userId?" element={<Layout><User/></Layout>}/>
                            <Route path="/projects" element={<Layout><Projects/></Layout>}/>
                            <Route path="/project/:projectId?" element={<Layout><Project/></Layout>}/>
                            <Route path="/chats/:userId/:chatId?" element={<Layout><Chats/></Layout>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="*" element={<Navigate to={defaultPath} replace/>}/>
                        </Routes>
                    </WebSocketProvider>
                ) : (
                    <Routes>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="*" element={<Navigate to={defaultPath} replace/>}/>
                    </Routes>
                )}
            </div>
        </Router>
    );
}

export default App;
