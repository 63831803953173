import React, { useState, useEffect } from "react";
import { Editor, EditorState, CompositeDecorator, getDefaultKeyBinding } from "draft-js";
import { post } from "../../api";
import { usePopper } from "react-popper";
import ESAttachment from "./items/attachment/ESAttachment";
import ESMessage from "./items/message/ESMessage";
import ESUser from "./items/user/ESUser";
import "./ElasticSearch.scss";
import AllItemsModal from "./AllItemsModal";
import "draft-js/dist/Draft.css";
import {findWithRegex, regexPatterns} from "./elasticsearchUtil";

const ElasticSearch = () => {
    const [hits, setHits] = useState({ users: [], messages: [], attachments: [] });
    const [dropdownRef, setDropdownRef] = useState(null);
    const [showPopper, setShowPopper] = useState(false);
    const [isMessagesModalOpen, setIsMessagesModalOpen] = useState(false);
    const [isAttachmentsModalOpen, setIsAttachmentsModalOpen] = useState(false);
    const [isUsersModalOpen, setIsUsersModalOpen] = useState(false);
    const [inputRef, setInputRef] = useState(null);

    const { styles, attributes } = usePopper(inputRef, dropdownRef, {
        placement: "bottom",
    });

    const createDecorator = () => {
        const decorators = Object.keys(regexPatterns).map((key) => ({
            strategy: (contentBlock, callback) => {
                findWithRegex(regexPatterns[key], contentBlock, callback);
            },
            component: (props) => (
                <span className="highlight">{props.children}</span>
            )
        }));
        return new CompositeDecorator(decorators);
    };

    const [editorState, setEditorState] = useState(() => EditorState.createEmpty(createDecorator()));

    const getQuery = () => {
        const content = editorState.getCurrentContent().getPlainText();
        if (content.length >= 1) {
            post("es/find", content)
                .then(response => {
                    setHits(response ? response : { users: [], messages: [], attachments: [] });
                    setShowPopper(true);
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });
        } else {
            setHits({ users: [], messages: [], attachments: [] });
            setShowPopper(false);
        }
    };

    useEffect(() => {
        getQuery();
    }, [editorState]);

    const keyBindingFn = (e) => {
        if (e.keyCode === 13) {
            return "newline";
        }
        return getDefaultKeyBinding(e);
    };

    return (
        <div className="elasticsearch">
            <div className="search">
                <img className="icon" src="/icons/search_icon.svg" alt="Search Icon" />
                <div className="editor-container" ref={setInputRef}>
                    <Editor
                        editorState={editorState}
                        onChange={setEditorState}
                        placeholder="Search..."
                        handleReturn={() => "handled"}
                        keyBindingFn={keyBindingFn}
                    />
                </div>
            </div>
            {showPopper && (
                <div className="popper-catcher" onClick={() => setShowPopper(false)}>
                    <div ref={setDropdownRef}
                         className="popper-overlay"
                         style={{...styles.popper}}
                         {...attributes.popper}
                         onClick={(e) => e.stopPropagation()}>
                        <div className="hit-list">
                            {hits.messages.length > 0 && (
                                <div>
                                    <div className="type">Messages:</div>
                                    {hits.messages.slice(0, 5).map(message => (
                                        <ESMessage message={message} query={editorState.getCurrentContent().getPlainText()} key={message.id} />
                                    ))}
                                    {hits.messages.length > 5 && <div className="more" onClick={() => setIsMessagesModalOpen(true)}>Show more...</div>}
                                </div>
                            )}
                            {hits.attachments.length > 0 && (
                                <div>
                                    <div className="type">Attachments:</div>
                                    {hits.attachments.slice(0, 5).map(attachment => (
                                        <ESAttachment attachment={attachment} query={editorState.getCurrentContent().getPlainText()} key={attachment.id} />
                                    ))}
                                    {hits.attachments.length > 5 && <div className="more" onClick={() => setIsAttachmentsModalOpen(true)}>Show more...</div>}
                                </div>
                            )}
                            {hits.users.length > 0 && (
                                <div>
                                    <div className="type">Users:</div>
                                    {hits.users.slice(0, 5).map(user => (
                                        <ESUser user={user} query={editorState.getCurrentContent().getPlainText()} key={user.id} />
                                    ))}
                                    {hits.users.length > 5 && <div className="more" onClick={() => setIsUsersModalOpen(true)}>Show more...</div>}
                                </div>
                            )}
                        </div>
                        <AllItemsModal type="Messages" items={hits.messages} query={editorState.getCurrentContent().getPlainText()} isModalOpen={isMessagesModalOpen} setIsModalOpen={setIsMessagesModalOpen} />
                        <AllItemsModal type="Attachments" items={hits.attachments} query={editorState.getCurrentContent().getPlainText()} isModalOpen={isAttachmentsModalOpen} setIsModalOpen={setIsAttachmentsModalOpen} />
                        <AllItemsModal type="Users" items={hits.users} query={editorState.getCurrentContent().getPlainText()} isModalOpen={isUsersModalOpen} setIsModalOpen={setIsUsersModalOpen} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default ElasticSearch;
