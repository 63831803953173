import React, {createContext, useEffect, useState} from "react";
import WebSocketService from "./webSocektService";

export const WebSocketContext = createContext();

const WebSocketProvider = ({userId, children}) => {

    const [lastMessage, setLastMessage] = useState(null);
    const [lastReaction, setLastReaction] = useState(null);
    const [lastAttachment, setLastAttachment] = useState(null);
    const [lastCallAction, setLastCallAction] = useState(null);
    const [lastMention, setLastMention] = useState(null);

    const onMessageReceived = (data) => {
        if (data.type === "message") {
            setLastMessage(data.data);
        } else if (data.type === "reaction") {
            setLastReaction(data.data);
        } else if (data.type === "attachment") {
            setLastAttachment(data.data);
        } else if (data.type === "callAction") {
            setLastCallAction(data.data);
        } else if (data.type === "mention") {
            setLastMention(data.data);
        }
    };

    useEffect(() => {
        const webSocketService = new WebSocketService(userId, onMessageReceived);
        webSocketService.connect();

        return () => {
            webSocketService.close();
        };
    }, [userId]);

    return (
        <WebSocketContext.Provider value={{lastMessage, lastReaction, lastAttachment, lastCallAction, lastMention}}>
            {children}
        </WebSocketContext.Provider>
    );
}

export default WebSocketProvider;