import React from "react";
import "../../Form.scss";
import "./RadioInputWrapper.scss";


const RadioInputWrapper = ({label, children}) => {
    return (
        <div className="radio-input-wrapper">
            <div className="row">
                <label className="label">
                    {label}
                </label>
                <span>
                    {children}
                </span>
            </div>
        </div>
    );
}

export default RadioInputWrapper;