import React from "react";
import "./ReactionSelector.scss";
import {reactionsDict} from "../../../constants";
import {useParams} from "react-router-dom";
import {handleAddOrRemoveReaction} from "../reactionCommons";
import classNames from "classnames";

const ReactionSelector = ({messageId, position, reactions, isVisible}) => {
    const {chatId, userId} = useParams()

    return (
        <div className={`reaction-selector ${position} ${isVisible ? "visible" : ""}`}>
            {Object.values(reactionsDict).map(emojiCode => (
                <button key={emojiCode} className={classNames("emoji-button", {
                    "is-selected": reactions.filter(reaction => reaction.user_id === userId).some(reaction => reaction.code === emojiCode)
                })}
                        onClick={() => handleAddOrRemoveReaction(reactions, emojiCode, chatId, userId, messageId)}>
                    {emojiCode.split(" ").map(code => String.fromCodePoint(parseInt(code, 16))).join("")}
                </button>
            ))}
        </div>
    );
};

export default ReactionSelector;
