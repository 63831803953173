import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {connectCall, closeCall, setCallModalOpen} from "../store/callSlice";
import "./JitsiCallModal.scss";
import {JitsiMeeting} from "@jitsi/react-sdk";
import {get} from "../api";
import appConfig from "../config/applicationConfiguration";

const JitsiCallModal = () => {
    const isOpen = useSelector(state => state.call.isCallModalOpen);
    const [token, setToken] = useState(null);
    const callName = useSelector(state => state.call.callName);
    const isConnected = useSelector(state => state.call.isConnected);
    const user = useSelector(state => state.user.user);
    const chatId = useSelector(state => state.call.callChannelId);
    const dispatch = useDispatch();
    const [joined, setJoined] = useState(false);

    useEffect(() => {
        get("auth/jitsi/token")
            .then(token => {
                    setToken(token.jitsi_token);
                }
            )
    }, []);

    const ping = () => {
        get(`call/${chatId}/ping`)
    }

    useEffect(() => {
        if (joined) {
            ping();
            const interval = setInterval(() => {
                ping();
            }, parseInt(appConfig.pingInterval));

            return () => {
                clearInterval(interval);
                dispatch(closeCall());
            }
        }
    }, [joined]);



    return (
        <button
            className="jitsi-call-modal"
            style={{display: isOpen ? "" : "none"}}
            onClick={() => isConnected ? dispatch(setCallModalOpen(false)) : dispatch(closeCall())}
        >
            {token && <JitsiMeeting
                domain={appConfig.jitsiDomain}
                jwt={token}
                roomName={callName}
                configOverwrite={{
                    startWithAudioMuted: true,
                    startWithVideoMuted: true,
                    apiLogLevels: ["error", "warn"],
                    disableModeratorIndicator: true,
                    toolbarButtons: [
                        "camera",
                        "desktop",
                        "download",
                        "fullscreen",
                        "hangup",
                        "livestreaming",
                        "microphone",
                        "noisesuppression",
                        "participants-pane",
                        "profile",
                        "raisehand",
                        "select-background",
                        "settings",
                        "shareaudio",
                        "sharedvideo",
                        "shortcuts",
                        "stats",
                        "tileview",
                        "toggle-camera",
                        "videoquality",
                    ],
                }}
                userInfo={{
                    displayName: `${user.name} ${user.surname}`,
                    email: user.email,
                }}
                onReadyToClose={() => dispatch(closeCall())}
                displayName={callName}
                onApiReady={api => {
                    api.addListener("videoConferenceJoined", participant => {
                        dispatch(connectCall());
                        setJoined(true);
                    });
                }}
                getIFrameRef={iframeRef => {
                    iframeRef.style.width = "90%";
                    iframeRef.style.height = "90%";
                    iframeRef.style.zIndex = "9999";
                }}
            />}
        </button>
    );
};

export default JitsiCallModal;
