import axios from "axios";
import appConfig from "./config/applicationConfiguration";

axios.defaults.baseURL = appConfig.apiUrl;

const getBearerToken = () => `Bearer ${
    JSON.parse(localStorage.getItem("state"))?.user?.token
}`;

export const jsonHeader = {
    "Content-Type": "application/json",
    get "Authorization"() {
        return getBearerToken();
    },
}

export const dataHeader = {
    "Content-Type": "multipart/form-data",
    get "Authorization"() {
        return getBearerToken();
    },
}

export const get = (url, header = jsonHeader) => {
    return axios.get(url, {
        headers: {...header},
        responseType: header === dataHeader ? "blob" : "json"
    })
        .then(response => response.data)
        .catch(error => {
            console.error(`There was an error fetching the data from ${url}!`, error);
            throw error;
        });
}

export const post = (url, data, header = jsonHeader) => {
    return axios.post(url, data, {headers: {...header}})
        .then(response => response.data)
        .catch(error => {
            console.error(`There was an error posting the data to ${url}!`, error);
            throw error;
        });
}

export const put = (url, data, header = jsonHeader) => {
    return axios.put(url, data, {
        headers: {...header},
        responseType: header === dataHeader ? "blob" : "json"
    })
        .then(response => response.data)
        .catch(error => {
            console.error(`There was an error putting the data to ${url}!`, error);
            throw error;
        });
}

export const remove = (url, header = jsonHeader) => {
    return axios.delete(url, {headers: {...header}})
        .then(response => response.data)
        .catch(error => {
            console.error(`There was an error deleting the data from ${url}!`, error);
            throw error;
        });
}

export const login = (loginData) => {
    return axios.post("auth/login", loginData, {headers: {"Content-Type": "application/json"}})
        .then(response => response.data)
        .catch(error => {
            console.error("There was an error logging in the user!", error);
            throw error;
        });
}
