import React from "react";
import "../../Form.scss";
import "./ColorInput.scss";


const ColorInput = ({label, name, formik, required = true}) => {
    return (
        <div className="color-input">
            <div className="row">
                <label className="label">
                    {required && <span className="required-asterisk">*</span>}
                    {label}
                </label>
                <input
                    className={`${formik.touched[name] && formik.errors[name] ? "error-input" : ""}`}
                    type="color"
                    name={name}
                    value={formik.values[name] || "#FFFFFF"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
            </div>
        </div>
    );
}

export default ColorInput;