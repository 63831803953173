import React, {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {get} from "../../../api";
import ProjectActionWrapper from "./ProjectActionWrapper";
import {useFormik} from "formik";
import * as Yup from "yup";
import {
    saveOrUpdateImage,
    saveProject,
    updateProject
} from "../../../components/forms/CommonFormRequests";
import _ from "lodash";
import UserSelect from "../../../components/forms/input-primitives/select/user-select/UserSelect";
import TextInput from "../../../components/forms/input-primitives/text-input/TextInput";
import ColorInput from "../../../components/forms/input-primitives/color-input/ColorInput";
import ImageDropzone from "../../../components/forms/input-primitives/image-dropdown/ImageDropzone";

const Project = () => {
    const {projectId} = useParams();
    const isEditing = !!projectId;
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [project, setProject] = useState(null);

    useEffect(() => {
        get("users").then(users => {
            setUsers(users);
        });
    }, []);

    useEffect(() => {
        if (projectId) {
            get(`projects/${projectId}`)
                .then(projects => {
                    setProject(projects)
                })
                .catch(error => {
                    console.error("There was an error fetching the project!", error);
                });
        }
    }, [projectId]);

    useEffect(() => {
        if (isEditing && project) {
            get(`project/${project.id}/users`).then(users => {
                formik.setValues(
                    {
                        ...project,
                        users: users,
                    }
                )
            });
        }
    }, [project]);


    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        short_name: Yup.string()
            .required("Required")
            .test(
                "is-shorter-or-equal",
                "Short name must be shorter than 10 characters and be shorter or equal in length to name",
                function (value) {
                    const {name} = this.parent
                    if (!name) {
                        return true;
                    }
                    return value.length <= name.length && value.length <= 10;
                }
            ),
        color: Yup.string().required("Required"),
        users: Yup.array().min(1, "At least one user is required"),
    });

    const handleSubmit = (values, {setSubmitting}) => {
        const projectBody = {
            ..._.pick(values, "name", "color", "short_name"),
            id: isEditing ? project.id : null,
        };

        let promises = [];

        if (values.projectPicture) {
            promises.push(
                saveOrUpdateImage(values.projectPicture, isEditing ? project.document_id : null)
                    .then((imageData) => {
                        projectBody.document_id = isEditing && project.document_id ? project.document_id : imageData.id;
                        promises.push(
                            isEditing ? updateProject(projectBody, values.users, project.id) : saveProject(projectBody, values.users));
                    })
                    .catch((error) => {
                        console.error("There was an error saving the image!", error);
                    })
            );
        } else {
            promises.push(isEditing ? updateProject(projectBody, values.users, project.id) : saveProject(projectBody, values.users));
        }

        Promise.all(promises)
            .then(() => {
                navigate("/projects");
            })
            .catch((error) => {
                console.error("There was an error saving the project", error);
            })
            .finally(() => {
                formik.resetForm();
                setSubmitting(false);
            });
    };

    const formik = useFormik({
            initialValues: {
                name: "",
                short_name: "",
                color: "",
                users: [],
                projectPicture: null,
            },
            validationSchema: validationSchema,
            onSubmit: handleSubmit,
        }
    );

    return (
        <ProjectActionWrapper onSubmit={formik.handleSubmit} path={`/project/${projectId}`}
                              project={project ? project : {name: "New"}}>
            <form className="form" onSubmit={formik.handleSubmit}>
                <ImageDropzone label="Logo" name="projectPicture" formik={formik}/>
                <TextInput label="Name" name="name" formik={formik}/>
                <TextInput label="Short Name" name="short_name" formik={formik}/>
                <ColorInput label="Color" name="color" formik={formik}/>
                <UserSelect label="Users" name="users" users={users} formik={formik}/>
            </form>
        </ProjectActionWrapper>
    );
}

export default Project;