import React, {useState} from "react";
import {remove} from "../../api";
import Modal from "react-modal";
import Card from "./Card";
import Image from "../image/Image";

const ProjectCardActions = ({project, onDelete}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleProjectDelete = () => {
        setModalIsOpen(true);
    };

    const deleteProjectAndCloseModal = () => {
        remove(`projects/${project.id}`)
            .then(() => {
                onDelete(project.id);
            })
            .catch(error => {
                console.error("There was an error deleting the project!", error);
            });
        setModalIsOpen(false);
    };

    return (
        <>
            <Card link={`/project/${project.id}`} onDelete={handleProjectDelete}>
                <Image imageId={project.document_id} name={project.name} color={project.color}/>
                <div className="item-name">
                    {project.name}
                </div>
            </Card>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                className="modal"
                overlayClassName="overlay"
                contentLabel="Delete Project Confirmation"
            >
                <p>Are you sure you want to delete this project?</p>
                <button onClick={deleteProjectAndCloseModal} className="button primary">Yes</button>
                <button onClick={() => setModalIsOpen(false)} className="button secondary">No</button>
            </Modal>
        </>
    );
};

export default ProjectCardActions;
