import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {client} from "scrumship-client";
import Modal from "react-modal";
import {Provider} from "react-redux";
import store from "./store/store";

client.log("wgDgbQI");

Modal.setAppElement("#root");

ReactDOM.render(
    <Provider store={store}>
        <App/>,
    </Provider>,
    document.getElementById("root")
)
;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
